import {createTheme} from '@mui/material'

const palette3 = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      light: '#FAFAFA',
      gray: '#d8d8d8',
      greyLight: '#f9f9f9',
      contrastGrey: '#DBDBDB',
      lightDark: '#333333',
      border: '#000000',
    },
    primary: {
      main: '#DB0025',
      light: '#2ed0ff',
      contrastText: '#ffffff',
      hoverLight: '#ffe2e7',
    },
    secondary: {
      main: '#fc990f',
      light: '#ffc74f',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#da0127',
      light: '#ff4591',
    },
    quaternary: {
      main: '#003189',
      light: '#3366CC',
    },
    searchBar: {
      backgroundColor: '#efefef',
      border: '#ededed',
      color: '#a0a0a0',
    },

    socialButton: {
      border: '#e0e0e0',
    },
    section: {
      border: '#e8e8e8',
    },
    tags: {
      departments: {
        color: '#0bb3ee',
        backgroundColor: '#E0F0F7',
      },
      industries: {
        backgroundColor: '#F7EEE0',
      },
      partners: {
        backgroundColor: '#F4DFE3',
      },
      tags: {
        color: '#DB0025',
        backgroundColor: '#ffe2e7',
      },
    },
    tagButton: {
      border: '#d5d5d5',
    },
    text: {
      primary: '#333333',
      light: '#ffffff',
      dark: '#000000',
      grey: '#9d9d9d',
    },
    warn: {
      main: '#930053',
    },
    success: {
      main: '#4caf50',
    },
    input: {
      background: '#e9e9e9',
    },
    markerCloseBackground: '#ffffff7d',
    capitalStructure: '#f2f2f2',
    worldMap: {
      coverage: '#B6CED7',
    },
    filtered: {
      primary:
        'invert(84%) sepia(46%) saturate(6454%) hue-rotate(353deg) brightness(103%) contrast(98%)',
      instagram:
        'invert(29%) sepia(100%) saturate(7466%) hue-rotate(330deg) brightness(93%) contrast(96%)',
      linkedIn:
        'invert(25%) sepia(79%) saturate(2949%) hue-rotate(183deg) brightness(92%) contrast(101%)',
    },
  },
})

export default palette3
