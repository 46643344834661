import * as R from 'ramda'
import {bool, func, node, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import classNames from 'classnames'
import Fade from '@mui/material/Fade'
import React, {useEffect, useState} from 'react'

import {dynamicRouting} from 'helpers/utils'
import {pageContextPropTypes} from 'helpers/propTypes'

import {
  renderCustomServicesMenu,
  replaceServiceMenu,
} from 'helpers/customServicesMenu'
import CloseButton from 'components/UI/CloseButton'
import DropDownLink from 'components/Layout/DropDownLinkKm'
import RoundButton from 'components/UI/RoundButton'
import Search from 'components/UI/Search'
import SwitchLanguageSmall from 'components/Layout/SwitchLanguageSmall'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Header = ({
  pageContext,
  countryData,
  data,
  pageData,
  showPopper,
  setShowPopper,
  setPopperLink,
  countryServices,
}) => {
  const {nodeLocale, prefix, hasShortPath} = pageContext
  const {countryCode, technicalName, navLocale, isMultiLang} = countryData
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLangOpen, setIsModalLangOpen] = useState(false)

  const classes = useStyles()
  const isMobile = useIsMobile()

  useEffect(() => {
    document.body.style.overflowY =
      isModalOpen || isModalLangOpen ? 'scroll' : 'scroll'
  }, [isModalOpen, isModalLangOpen])

  function toggleSwitchLang(value) {
    setIsModalOpen(false)
    setIsModalLangOpen(value || !isModalLangOpen)
  }

  function openMenuMobile() {
    setIsModalOpen(true)
    setIsModalLangOpen(false)
  }

  const localizedHeader = data.allContentfulHeader.edges.filter(
    el => el.node.node_locale === nodeLocale,
  )

  const localizedSearchPlaceholder = data.allContentfulPlaybook.edges.filter(
    el => el.node.node_locale === nodeLocale,
  )

  const {logo, headerMenu} = R.pathOr(null, [0, 'node'], localizedHeader)

  const {searchPlaceholder} = R.pathOr(
    null,
    [0, 'node'],
    localizedSearchPlaceholder,
  )

  const contactUs = R.pathOr(null, [0, 'node', 'contactUs'], localizedHeader)

  const contactUsLink = R.equals(countryCode, 'worldwide')
    ? `/${countryCode}/${contactUs.link}`
    : `/${countryCode}/${navLocale}/${contactUs.link}`

  const renderLogoCorp = modal => (
    <Link
      to={dynamicRouting(prefix, 'home', countryCode, hasShortPath, navLocale)}
      className={classNames(classes.logoKeyrus, {
        [classes.logoKeyrusModal]: isModalOpen && modal,
      })}
    >
      <img alt={logo.title} src={logo.file.url} loading="lazy" />
    </Link>
  )

  const isServicesItem = R.either(
    R.propEq('link', 'services'),
    R.propSatisfies(link => link.startsWith('services/'), 'link'),
  )

  const servicesItems =
    R.filter(isServicesItem, headerMenu.menuItems)[0].childMenu.menuItems &&
    R.filter(isServicesItem, headerMenu.menuItems)[0].childMenu.menuItems[0]

  const customMenu = renderCustomServicesMenu(
    countryServices,
    technicalName === 'France' || technicalName === 'Belgium'
      ? servicesItems
      : {},
  )[0].childMenu

  const updatedMenu = replaceServiceMenu(customMenu, headerMenu.menuItems)

  const concatSubLevelServicesIds = R.pipe(
    R.pluck('subLevelServicesIds'),
    R.flatten,
    R.pluck('entryId'),
  )

  const countrySubServices = concatSubLevelServicesIds(countryServices)

  const renderNavList = () => (
    <>
      {R.map(
        ({
          id,
          title,
          link,
          childMenu,
          isExternalLink,
          isOpenInNewWindow,
          isSameMenuItemForAllCountries,
          menuItemPerCountry,
        }) => (
          <DropDownLink
            menuItemPerCountry={menuItemPerCountry}
            country={technicalName}
            menuTitle={title}
            menuLink={link}
            childMenu={childMenu}
            key={`${id}`}
            isItAnExternalLink={isExternalLink}
            shouldThisLinkOpenInANewBrowserWindow={isOpenInNewWindow}
            isSameMenuItemForAllCountries={isSameMenuItemForAllCountries}
            prefix={prefix}
            hasShortPath={hasShortPath}
            countryCode={countryCode}
            navLocale={navLocale}
            setShowPopper={() => setShowPopper(true)}
            togglePopper={() => setShowPopper(!showPopper)}
            setPopperLink={val => setPopperLink(val)}
          />
        ),
        updatedMenu,
      )}
      {!isMobile && (
        <Search
          country={countryCode}
          navLocale={navLocale}
          searchPlaceholder={searchPlaceholder}
          isMultiLang={isMultiLang}
          countrySubServices={countrySubServices}
          pageData={pageData}
        />
      )}
      <div className={classes.navigationItem}>
        <RoundButton
          color="primary"
          centered
          isSmallText
          isFilled={false}
          href={contactUsLink}
          isNotBold
        >
          {contactUs.title}
        </RoundButton>
      </div>
      <div
        className={classNames(classes.bridgeHover, {
          [classes.bridgeHoverShape]: isModalLangOpen,
        })}
      >
        <button
          onClick={() => toggleSwitchLang()}
          type="button"
          className={classes.switchLanguageButton}
        >
          {R.toUpper(countryCode)}
          {!isMobile && isModalLangOpen && (
            <SwitchLanguageSmall
              currentCountry={countryCode}
              allSitePage={data.allSitePage.distinct}
            />
          )}
          {isMobile && (
            <SwitchLanguageSmall
              currentCountry={countryCode}
              allSitePage={data.allSitePage.distinct}
            />
          )}
        </button>
      </div>
    </>
  )

  const renderMobileNav = () => (
    <div className={classes.renderMobileNav}>
      <div>
        <nav role="navigation" className={classes.menu}>
          {renderLogoCorp()}
          <div style={{display: 'flex'}}>
            <Search
              country={countryCode}
              navLocale={navLocale}
              isMultiLang={isMultiLang}
              searchPlaceholder={searchPlaceholder}
              countrySubServices={countrySubServices}
              pageData={pageData}
            />
            <button
              aria-label="open menu"
              label="open menu"
              type="button"
              onClick={() => openMenuMobile()}
              className={classes.hamburger}
            />
          </div>
          {isModalOpen && isMobile && (
            <>
              <div className={classes.bgModal} />
              <Fade appear={false} in={isModalOpen}>
                <aside className={classes.modal}>
                  <ul className={classes.navigation}>
                    {isMobile && (
                      <>
                        <CloseButton action={setIsModalOpen} />

                        {renderLogoCorp('modal')}
                        <div className={classes.scrollable}>
                          {renderNavList()}
                        </div>
                      </>
                    )}
                  </ul>
                </aside>
              </Fade>
            </>
          )}
        </nav>
      </div>
    </div>
  )

  const renderDeskopNav = () => (
    <div className={classes.renderDeskopNav}>
      <div className={classes.subContainer}>
        <nav role="navigation" className={classes.menu}>
          {renderLogoCorp()}
          <ul className={classes.navigation}>{renderNavList()}</ul>
        </nav>
      </div>
    </div>
  )

  return (
    <header className={classes.container}>
      {renderMobileNav()}
      {renderDeskopNav()}
    </header>
  )
}

Header.propTypes = {
  countryData: shape({
    countryCode: string,
    technicalName: string,
    urlPrefix: string,
    navLocale: string,
    isMultiLang: bool,
  }),
  data: node,
  pageContext: pageContextPropTypes,
  setPopperLink: func,
  setShowPopper: func,
  showPopper: bool,
}

Header.defaultProps = shape({
  configSlug: null,
  contentId: '',
  menuItemsQuery: null,
  setPopperLink: null,
  setShowPopper: null,
  showPopper: false,
})

export default Header
