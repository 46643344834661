import React from 'react'
import ReactDOM from 'react-dom'

import LayoutKm from 'components/Layout/LayoutKm'

import './src/styles/global.css'
import './static/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useLayoutData} from './src/hooks/useLayoutData'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const shouldUpdateScroll = () => {
  if (window.location.href.indexOf('#') > -1) {
    return true
  }

  return [0, 0]
}

export const wrapPageElement = ({element, props}) => {
  const {pageResources} = props
  const is404 = pageResources.page.path.includes('404')

  const pageIsSSr = pageResources.page.path.includes('ssr')

  const Wrapper = () => {
    const layoutData = useLayoutData()

    return (
      <LayoutKm is404={is404} layoutData={layoutData} {...props}>
        {element}
      </LayoutKm>
    )
  }

  return pageIsSSr ? <div>{element}</div> : <Wrapper />
}
